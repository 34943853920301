import { ReactComponent as UserPosition } from "assets/icons/ico_user_position.svg";
import { ReactComponent as TwoUsers } from "assets/icons/ico_two_users.svg";
import { ReactComponent as PermitIcon } from "assets/icons/ico_permit.svg";
import { ReactComponent as QueueSettings } from "assets/icons/ico_queue_green.svg";
import { useParkingArea } from "context/parkingArea";
import ButtonText from "../../components/common/buttonText";
import { useTranslation } from "react-i18next";

export const PermitQueuePreview = ({ queueConfig }) => {
  const { parkingArea } = useParkingArea();
  const { t } = useTranslation();

  return (
    <>
      <div>
        <h2 className="text-md font-bold mb-2 items-left">
        {t("permitQueuePage.mobileAppPreview")}
        </h2>
        <div className="bg-gray-50 border mr-32 rounded-xl p-2 w-80">
          <div className="flex flex-col justify-start p-4 items-center mt-1 gap-2 h-full">
            <div className="bg-gray-200 border-2 shadow-md flex-col rounded-md mb-2 p-2 w-full">
              {(queueConfig.isSpecificSpotsQueueEnabled ||
                queueConfig.isAreaParkingQueueEnabled) && (
                <div className="flex">
                  <div>
                    <div className="flex flex-row gap-2 items-center mb-4">
                      <QueueSettings className="w-12 h-12" />
                      <div className="flex flex-col">
                        <b className="text-gray-00">{t("permitQueuePage.joinParkingAreaQueue")}</b>
                      </div>
                    </div>
                    {!queueConfig.isQueueLengthInfoPublic &&
                      !queueConfig.isUserQueuePositionInfoPublic && (
                        <div>
                          <p>{t("permitQueuePage.joinQueueAndGetNotified")}
                          </p>
                        </div>
                      )}
                    <div
                      className={`flex flex-row pl-2 items-center gap-2 mt-2 transition-all duration-500 ease-in-out overflow-hidden ${
                        queueConfig.isQueueLengthInfoPublic
                          ? "opacity-100 max-h-40"
                          : "opacity-0 max-h-0"
                      }`}
                    >
                      <TwoUsers className="w-4 h-4" />
                      <p>{t("permitQueuePage.usersInQueueCount")}</p>
                    </div>
                    <div className="rounded-xl mt-2 flex items-center justify-center ">
                      <ButtonText
                        className={
                          "w-full bg-airpark-green cursor-not-allowed !no-hover"
                        }
                        isQueue
                        onClick={() => {}}
                      >
                        {t("permitQueuePage.joinTheQueue")}
                      </ButtonText>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="bg-gray-200 h-[20px] rounded-md w-full"></div>
            <div className="bg-gray-200 h-[20px] rounded-md w-full"></div>

            <div className="bg-gray-200 border-2 shadow-md mt-2 flex-col rounded-xl mb-2 p-2 w-full">
              <div>
                <div>
                  <div className="flex flex-row gap-2 mt-2 items-center mb-4">
                    <PermitIcon className="w-8 h-8 bold" />
                    <div className="flex flex-col">
                      <b className="text-gray-00">{parkingArea.name}</b>
                      <span>{parkingArea.address}</span>
                    </div>
                  </div>
                  <div
                    className={`flex flex-row pl-2 items-center gap-2 mt-2 transition-all duration-500 ease-in-out overflow-hidden ${
                      queueConfig.isQueueLengthInfoPublic
                        ? "opacity-100 max-h-40"
                        : "opacity-0 max-h-0"
                    }`}
                  >
                    <TwoUsers className="w-4 h-4" />
                    <p>{t("permitQueuePage.usersInQueueCount")}</p>
                  </div>
                  <div
                    className={`flex flex-row items-center pl-2 gap-2 mt-2 transition-all duration-500 ease-in-out overflow-hidden ${
                      queueConfig.isUserQueuePositionInfoPublic
                        ? "opacity-100 max-h-40"
                        : "opacity-0 max-h-0"
                    }`}
                  >
                    <UserPosition className="w-4 h-4" />
                    <p>{t("permitQueuePage.userQueuePosition")}</p>
                  </div>
                  <div className="rounded-xl mt-2 flex items-center justify-center ">
                    <ButtonText
                      className={
                        "w-full bg-gray-50 cursor-not-allowed text-[#FF4281] "
                      }
                      isQueue
                      onClick={() => {}}
                    >
                      {t("permitQueuePage.leaveTheQueue")}
                    </ButtonText>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
