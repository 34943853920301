import React, { useEffect, useState } from 'react';
import { ComposedChart, Line, XAxis, YAxis, Legend, ResponsiveContainer, Bar, Tooltip } from 'recharts';
import Widget from 'components/dashboard/widget';
import { useDashboard } from 'context/dashboard';
import { useTranslation } from 'react-i18next';
import Alert from 'components/common/alert';
import { useNavigate } from 'react-router-dom';
import { getToken } from 'context/auth';
import { getDashboardStatistics } from 'api/statistics';
import WidgetLoadingSkeleton from 'components/dashboard/widgetLoadingSkeleton';
import { useMessages } from 'context/messages';
import { formatCurrency } from 'helpers/currency';

const DashboardPage = () => {

    const { user, organization } = useDashboard();
    const { showToast } = useMessages();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [dashboardStatistics, setDashboardStatistics] = useState();

    useEffect(() => {
        const load = async () => {
            try {
                const token = await getToken();
                const result = await getDashboardStatistics(token, organization.organizationExtId);

                if (result && !result.error) {
                    setDashboardStatistics(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                console.error(error);
            }

            setLoading(false);
        }

        load();
        console.log('/');
        
    }, [organization])

    const renderTranslatedLegend = (value) => {
        return <span>{t(`legend.${value}`)}</span>;
    }

    const customTickFormatter = (amount) => {
        return `${Math.ceil(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="shadow-widget p-6 rounded-xl bg-white">
                    <p className="font-medium">{`${label}`}</p>
                    <p className="label text-airpark-green">{`${t('legend.bookingsAmount')}: ${formatCurrency(payload[0].value)} ${organization.currencySymbol}`}</p>
                    <p className="label text-airpark-sea">{`${t('legend.permitsAmount')}: ${formatCurrency(payload[1].value)} ${organization.currencySymbol}`}</p>
                    <p className="label text-airpark-coral">{`${t('legend.controlFeesAmount')}: ${formatCurrency(payload[2].value)} ${organization.currencySymbol}`}</p>
                    <p className="label text-airpark-green-400">{`${t('legend.totalAmount')}: ${formatCurrency(payload[3].value)} ${organization.currencySymbol}`}</p>
                </div>
            );
        }

        return null;
    };

    if (loading || !dashboardStatistics) {
        return (<WidgetLoadingSkeleton />);
    }

    return (
        <div className="flex flex-col gap-y-6">
            {!dashboardStatistics.hasSetupParkingArea &&
                <Alert
                    template='info'
                    title={t('dashboardPage.greeting', { name: user?.firstName })}
                    description={t('dashboardPage.createParkingAreaDescription')}
                    onClick={() => { navigate(`/${organization.organizationExtId}/create-parking-area/area`); }}
                    clickTitle={t('dashboardPage.createParkingAreaTitle')} />
            }
            {dashboardStatistics.hasSetupParkingArea &&
                <Widget>
                    <h1 className="text-xl font-medium">{t('dashboardPage.greeting', { name: user?.firstName })}</h1>
                    <span className="mt-1">{t('dashboardPage.hereIsYourAirParkOverview')}</span>
                </Widget>
            }
            <div className="flex md:flex-row flex-col gap-6">
                <Widget className="flex md:w-1/4 items-center justify-between">
                    <h2 className="text-center">{t('dashboardPage.bookingsLastTwentyFourHours')}</h2>
                    <span className="text-xl font-medium mt-3">{dashboardStatistics.bookingLastTwentyFourHours}</span>
                </Widget>
                <Widget className="flex md:w-1/4 items-center justify-between">
                    <h2 className="text-center">{t('dashboardPage.activeParkingPermits')}</h2>
                    <span className="text-xl font-medium mt-3">{dashboardStatistics.numberOfActivePermits}</span>
                </Widget>
                <Widget className="flex md:w-1/4 items-center justify-between">
                    <h2 className="text-center">{t('dashboardPage.totalPayouts')}</h2>
                    <span className="text-xl font-medium mt-3">{`${formatCurrency(dashboardStatistics.totalPayoutAmountLastTwelveMonths)} ${organization?.currencySymbol}`}</span>
                </Widget>
                <Widget className="flex md:w-1/4 items-center justify-between">
                    <h2 className="text-center">{t('dashboardPage.pendingPayouts')}</h2>
                    <span className="text-xl font-medium mt-3">{`${formatCurrency(dashboardStatistics.pendingPayoutAmount)} ${organization?.currencySymbol}`}</span>
                </Widget>
            </div>
            {loading &&
                <WidgetLoadingSkeleton />
            }
            {!loading && dashboardStatistics &&
                <Widget className="min-h-[420px]">
                    <h2 className="text-xl font-medium">{t('dashboardPage.revenue')}</h2>
                    <i className="mb-6 text-sm mt-1">{t('dashboardPage.revenueDescription')}</i>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart width={300} height={200} data={dashboardStatistics.yearlyRevenue?.reverse()}>
                            <Bar dataKey="bookingsAmount" barSize={20} fill="#33D085" />
                            <Bar dataKey="permitsAmount" barSize={20} fill="#2886FF" />
                            <Bar dataKey="controlFeesAmount" barSize={20} fill="#FF899E" />
                            <Line type="monotone" dataKey="totalAmount" stroke="#17BB6C" strokeWidth={2} />
                            <XAxis dataKey="yearMonth" />
                            <YAxis tickFormatter={customTickFormatter} unit={organization?.currencySymbol} width={80} />
                            <Legend formatter={renderTranslatedLegend} />
                            <Tooltip content={<CustomTooltip />} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Widget>
            }
        </div>
    )
}

export default DashboardPage;