import objectToFormData from "helpers/objectToFormData";

const BASE_URL = `${process.env.REACT_APP_API_LOCATIONS_BASE_URL}/Locations`;

const handleResponse = async (response) => {
  if (response.status !== 200) throw response;
  const responseBody = await response.text();
  return responseBody ? JSON.parse(responseBody) : {};
};

const fetchData = async (url, method, token, body = null, contentType = null) => {
  const headers = {
    'authorization': `Bearer ${token}`,
    ...(contentType && { 'content-type': contentType })
  };

  const options = { method, headers, ...(body && { body }) };
  const response = await fetch(url, options);
  return handleResponse(response);
};

export const getParkingAreasForOrganization = async (token, organizationExtId) =>
  fetchData(`${BASE_URL}/parking-areas-for-organization/${organizationExtId.toLowerCase()}`, 'GET', token, null, 'application/json');

export const getParkingArea = async (token, parkingAreaExtId) =>
  fetchData(`${BASE_URL}/parking-area/${parkingAreaExtId.toLowerCase()}`, 'GET', token, null, 'application/json');

export const createParkingArea = async (token, parkingArea) => {
  const formData = objectToFormData(parkingArea);
  return fetchData(`${BASE_URL}/create-parking-area`, 'POST', token, formData);
};

export const updateParkingArea = async (token, parkingArea) => {
  const formData = objectToFormData(parkingArea);
  return fetchData(`${BASE_URL}/update-parking-area`, 'PUT', token, formData);
};

export const deleteParkingArea = async (token, parkingAreaExtId) =>
  fetchData(`${BASE_URL}/delete-parking-area/${parkingAreaExtId}`, 'DELETE', token, null, 'application/json');

export const deleteParkingAreaImage = async (token, parkingAreaExtId, parkingAreaImageFileName) =>
  fetchData(`${BASE_URL}/delete-parking-area-image/${parkingAreaExtId}/${parkingAreaImageFileName}`, 'DELETE', token, null, 'application/json');

export const getParkingSpots = async (token, parkingAreaExtId) =>
  fetchData(`${BASE_URL}/parking-spots/${parkingAreaExtId.toLowerCase()}`, 'GET', token, null, 'application/json');

export const deleteParkingSpots = async (token, parkingSpotExtIds) =>
  fetchData(`${BASE_URL}/delete-parking-spots`, 'DELETE', token, JSON.stringify(parkingSpotExtIds), 'application/json');

export const updateParkingSpot = async (token, parkingSpot) =>
  fetchData(`${BASE_URL}/update-parking-spot`, 'PUT', token, JSON.stringify(parkingSpot), 'application/json');

export const addParkingSpotsToParkingArea = async (token, parkingSpots) =>
  fetchData(`${BASE_URL}/add-parking-spots-to-parking-area`, 'POST', token, JSON.stringify(parkingSpots), 'application/json');

export const getControlFeeSettings = async (token, parkingAreaExtId) =>
  fetchData(`${BASE_URL}/control-fee-settings/${parkingAreaExtId.toLowerCase()}`, 'GET', token, null, 'application/json');

export const updateControlFeeSettings = async (token, controlFeeSettings) =>
  fetchData(`${BASE_URL}/update-control-fee-settings-for-parking-area`, 'PUT', token, JSON.stringify(controlFeeSettings), 'application/json');

export const deleteParkingSpot = async (token, parkingSpotExtId) =>
  fetchData(`${BASE_URL}/parking-spot/${parkingSpotExtId}`, 'DELETE', token, null, 'application/json');
