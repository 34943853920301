import Widget from "components/dashboard/widget";
import React, { useState, useEffect } from "react";

import { ReactComponent as ArrowDownIcon } from "assets/icons/ico_arrow_down_green.svg";
import { ReactComponent as ArrowUpIcon } from "assets/icons/ico_arrow_up_green.svg";
import { getToken } from "context/auth";
import { useTranslation } from "react-i18next";
import { useMessages } from "context/messages";
import { useParams } from "react-router-dom";
import ButtonSlider from "components/common/buttonSlider";

import ButtonText from "../../components/common/buttonText";
import {
  addParkingAreaQueueSettings,
  getParkingAreaQueueSettings,
} from "api/permits";
import { PermitQueuePreview } from "./permitQueuePreview";
import { useSearchParams } from "react-router-dom";

const PermitQueueSettings = () => {
  const { t } = useTranslation();
  const { showToast } = useMessages();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openSettings, setOpenSettings] = useState(false);
  const [initQueueConfig, setInitQueueConfig] = useState({
    isAreaParkingQueueEnabled: false,
    isSpecificSpotsQueueEnabled: true,
    isQueueLengthInfoPublic: false,
    isUserQueuePositionInfoPublic: false,
  });
  const [queueConfig, setQueueConfig] = useState({
    isAreaParkingQueueEnabled: false,
    isSpecificSpotsQueueEnabled: true,
    isQueueLengthInfoPublic: false,
    isUserQueuePositionInfoPublic: false,
  });
  const { parkingAreaExtId } = useParams();

  useEffect(() => {
    if (!parkingAreaExtId) return;

    const fetchQueueSettings = async () => {
      try {
        const token = await getToken();
        const result = await getParkingAreaQueueSettings(
          token,
          parkingAreaExtId
        );

        if (result && !result.error) {
          setQueueConfig(result);
          setInitQueueConfig(result);
        } else {
          console.error(result.error);
          showToast(
            t("generic.errorTitle"),
            t("permitQueueTable.queueCantBeFetched"),
            "error"
          );
        }
      } catch (error) {
        console.error("erorcak", error);
        showToast(
          t("generic.errorTitle"),
          t("generic.errorDescription"),
          "error"
        );
      }
    };
    fetchQueueSettings();
  }, [parkingAreaExtId]);

  useEffect(() => {
    setSearchParams({
      positionInQueue: queueConfig.isUserQueuePositionInfoPublic,
    });
  }, [queueConfig.isUserQueuePositionInfoPublic]);

  const handleQueueSettingsSave = async () => {
    try {
      const token = await getToken();
      
      await addParkingAreaQueueSettings(
        token,
        parkingAreaExtId,
        queueConfig
      );
      showToast(
        t("permitQueuePage.queueSettingsSavedTitle"),
        t("permitQueuePage.queueSettingsSavedDescription"),
        "success"
      );
    } catch (error) {
      console.error(error);
      showToast(
        t("generic.errorTitle"),
        t("generic.errorDescription"),
        "error"
      );
    }
  };

  const Toggle = ({ label, checked, onChange }) => {
    return (
      <label className="relative inline-flex items-center cursor-pointer mb-2">
        <input
          type="checkbox"
          className="sr-only peer"
          checked={checked}
          onChange={onChange}
        />
        <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-green-500 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:w-5 after:h-5 after:bg-white after:border after:border-gray-300 peer-checked:after:border-green-500 after:rounded-full after:transition-all duration-200 ease-in-out"></div>
        <span className="ml-1 mr-4 text-sm font-medium text-gray-900">
          {label}
        </span>
      </label>
    );
  };

  return (
    <Widget className="mt-6">
      <div>
        <button
          className="w-full text-left px-1 py-3 flex justify-between items-center text-gray-700"
          onClick={() => setOpenSettings(!openSettings)}
        >
          <p className="text-xl font-medium">{t("permitQueuePage.queueSettings")}</p>
          <span>{openSettings ? <ArrowUpIcon /> : <ArrowDownIcon />}</span>
        </button>
        <div className="flex justify-between w-full flex-col md:flex-row gap-4">
          <div className={`${openSettings ? "block" : "hidden"} p-1 md:p-4`}>
            <div className="mb-4">
              <div className="mb-4">
                <ButtonSlider
                  title={t("permitQueuePage.allowUsersToJoinParkingQueue")}
                  isToggled={queueConfig.isAreaParkingQueueEnabled}
                  onClick={() =>
                    setQueueConfig({
                      ...queueConfig,
                      isAreaParkingQueueEnabled: !queueConfig.isAreaParkingQueueEnabled,
                    })
                  }
                />
              </div>
              <ButtonSlider
                title={t("permitQueuePage.allowUsersToJoinSpecificSpotsQueue")}
                isToggled={queueConfig.isSpecificSpotsQueueEnabled}
                onClick={() =>
                  setQueueConfig({
                    ...queueConfig,
                    isSpecificSpotsQueueEnabled:
                      !queueConfig.isSpecificSpotsQueueEnabled,
                  })
                }
              />
            </div>
            <hr className="mb-4"></hr>
            <div className="mb-4">
              <ButtonSlider
                title={t("permitQueuePage.allowUsersToSeeQueueCount")}
                isToggled={queueConfig.isQueueLengthInfoPublic}
                onClick={() =>
                  setQueueConfig({
                    ...queueConfig,
                    isQueueLengthInfoPublic: !queueConfig.isQueueLengthInfoPublic,
                  })
                }
              />
            </div>
            <div className="mb-4">
              <ButtonSlider
                title={t("permitQueuePage.allowUsersToSeeQueuePosition")}
                isToggled={queueConfig.isUserQueuePositionInfoPublic}
                onClick={() => {
                  setQueueConfig({
                    ...queueConfig,
                    isUserQueuePositionInfoPublic:
                      !queueConfig.isUserQueuePositionInfoPublic,
                  });
                }}
              />
            </div>
            {openSettings && (
              <div className="flex justify-end">
                <ButtonText
                  onClick={async () => {
                    handleQueueSettingsSave();
                  }}
                  isLoading={false}
                  className="w-24 mt-4"
                >
                  {t("permitQueueItemModal.buttonSave")}
                </ButtonText>
                <ButtonText
                  isDelete={true}
                  className="w-24 mt-4 ml-2"
                  onClick={() => {
                    setQueueConfig(initQueueConfig);
                  }}
                  disabled={
                    JSON.stringify(initQueueConfig) ===
                    JSON.stringify(queueConfig)
                  }
                >
                  {t("permitQueueItemModal.buttonCancel")}
                </ButtonText>
              </div>
            )}
          </div>
          {openSettings && (
            <PermitQueuePreview queueConfig={queueConfig}></PermitQueuePreview>
          )}
        </div>
      </div>
    </Widget>
  );
};

export default PermitQueueSettings;
