import React, { useEffect, useState } from "react";
import Widget from "components/dashboard/widget";
import InputText from "components/common/inputText";
import Alert from "components/common/alert";
import ButtonText from "components/common/buttonText";
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import { useTranslation } from "react-i18next";
import { useMessages } from "context/messages";
import { createSignatureForOrganization, getAvailableVatPercentagesForOrganization, getOrganizationForUser, updateOrganization } from "api/organizations";
import InputEmail from "components/common/inputEmail";
import { getToken } from "context/auth";
import { useParams, useSearchParams } from "react-router-dom";
import ButtonSlider from "components/common/buttonSlider";
import { AIRPARK_SUPPORT_EMAIL } from "helpers/consts";

const SettingsPage = () => {
    const { organizationExtId } = useParams();
    const [organization, setOrganization] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { showToast } = useMessages();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {

        setIsLoading(true);

        const load = async () => {
            try {
                const token = await getToken();
                const organizationResult = await getOrganizationForUser(token, organizationExtId);

                if (organizationResult && !organizationResult.error) {
                    setOrganization(organizationResult);
                } else {
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, []);

    useEffect(() => {
        if (searchParams.get('code')) {
            const save = async () => {
                try {
                    if (isSaving) {
                        return;
                    }

                    const token = await getToken();
                    let organizationResult = await getOrganizationForUser(token, organizationExtId);
                    console.log('got code:' + searchParams.get('code'));
                    const response = await createSignatureForOrganization(token,
                        {
                            signatureJWT: searchParams.get('code'),
                            organizationExtId: organizationExtId,
                            organizationNumber: organizationResult.organizationNumber
                        });

                    if (response?.error === 'INVALID_SIGNATURE') {
                        showToast(t('generic.errorTitle'), t('settingsPage.signatureError', { supportEmail: AIRPARK_SUPPORT_EMAIL}), 'error')
                    } else {
                        organizationResult = await getOrganizationForUser(token, organizationExtId);
                        setOrganization(organizationResult);
                    }
                } catch (error) {
                    console.error(error);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                } finally {
                    setIsSaving(false);
                    searchParams.delete('code');
                    setSearchParams(searchParams);
                }
            }

            setIsSaving(true);
            save();
        } else {
            console.log('no code');
        }
    }, []);

    const handleSign = async () => {
        const token = await getToken();
        const organizationResult = await getOrganizationForUser(token, organizationExtId);

        if (!organizationResult.organizationNumber) {
            showToast(t('generic.errorTitle'), 'You need to set the organization number first.', 'error');
            return;
        }

        try {
            window.location.href = `https://signature.airpark.app/?callback=${window.location.origin}/${organizationExtId}/settings`;
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }
    }

    const handleSave = async () => {

        if (isSaving) {
            return;
        }

        if (!organization.name ||
            !organization.organizationNumber ||
            !organization.address ||
            !organization.zipCode ||
            !organization.city ||
            !organization.phone ||
            !organization.email) {
            showToast(t('generic.missingFieldsTitle'), t('generic.missingFieldsDescription'), 'error');
            return;
        }

        if (organization.selfBilling && !organization.taxIdentificationNumber) {
            showToast(t('generic.missingFieldsTitle'), t('generic.missingFieldsDescription'), 'error');
            return;
        }

        setIsSaving(true);

        try {
            const token = await getToken();
            const result = await updateOrganization(token, organization);

            if (result && !result.error) {
                setOrganization(result);
                showToast(t('settingsPage.organizationUpdatedTitle'), t('settingsPage.organizationUpdatedDescription'), 'success');
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                return;
            }

        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsSaving(false);
    }

    if (isLoading) {
        return (<WidgetLoadingSkeleton />);
    }

    if (!isLoading && organization) {
        return (
            <div className="flex flex-col">
                <Widget className="mb-6">
                    <h1 className="text-xl font-medium pb-1">{t('settingsPage.title')}</h1>
                    <span>{t('settingsPage.description')}</span>
                    <div className="flex md:flex-row flex-col gap-3 mt-3">
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.name}
                                onChange={(value) => { setOrganization({ ...organization, name: value }) }}
                                title={t('settingsPage.name')}
                                required={true} />
                        </div>
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.organizationNumber ?? ''}
                                onChange={(value) => { setOrganization({ ...organization, organizationNumber: value }) }}
                                title={t('settingsPage.organizationNumber')}
                                required={true} />
                        </div>
                    </div>
                    <div className="flex gap-3 mt-3 md:flex-row flex-col">
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.taxIdentificationNumber ?? ''}
                                onChange={(value) => { setOrganization({ ...organization, taxIdentificationNumber: value }) }}
                                title={t('settingsPage.taxIdentificationNumber')}
                                required={organization.selfBilling ?? false} />
                        </div>
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.address ?? ''}
                                title={t('settingsPage.address')}
                                onChange={(value) => { setOrganization({ ...organization, address: value }) }}
                                required={true} />
                        </div>
                    </div>
                    <div className="flex gap-3 mt-3 md:flex-row flex-col">
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.zipCode ?? ''}
                                title={t('settingsPage.zipCode')}
                                onChange={(value) => { setOrganization({ ...organization, zipCode: value }) }}
                                required={true} />
                        </div>
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.city ?? ''}
                                title={t('settingsPage.city')}
                                onChange={(value) => { setOrganization({ ...organization, city: value }) }}
                                required={true} />
                        </div>
                    </div>
                    <div className="flex gap-3 mt-3 md:flex-row flex-col">
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.countryName ?? ''}
                                title={t('settingsPage.country')}
                                disabled={true} />
                        </div>
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.phone ?? ''}
                                title={t('settingsPage.phoneNumber')}
                                type="tel"
                                onChange={(value) => { setOrganization({ ...organization, phone: value }) }}
                                required={true} />
                        </div>
                    </div>
                    <div className="flex gap-3 mt-3 md:flex-row flex-col">
                        <div className="md:w-1/2">
                            <InputEmail
                                value={organization.email ?? ''}
                                title={t('settingsPage.email')}
                                onChange={(value) => { setOrganization({ ...organization, email: value }) }}
                                required={true} />
                        </div>
                        <div className="md:w-1/2">
                            <InputEmail
                                value={organization.invoiceEmail ?? ''}
                                title={t('settingsPage.invoiceEmail')}
                                onChange={(value) => { setOrganization({ ...organization, invoiceEmail: value }) }}
                                required={true} />
                        </div>
                    </div>
                    <div className="flex gap-3- mt-3 md:flex-row flex-col">
                        <div className="md:w-1/2 md:mt-8">
                            <ButtonSlider
                                onClick={() => { setOrganization({ ...organization, selfBilling: !organization.selfBilling }) }}
                                isToggled={organization.selfBilling ?? false}
                                tooltip={t('settingsPage.selfBillingTooltip')}
                                title={t('settingsPage.selfBilling')} />
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <ButtonText className="mt-6 max-w-xs w-full" onClick={() => { handleSave(); }} isLoading={isSaving}>
                            {t('generic.save')}
                        </ButtonText>
                    </div>
                </Widget>
                <Widget>
                    <h2 className="text-xl font-medium pb-1">{t('settingsPage.sign')}</h2>
                    <span className="mb-3 whitespace-pre-line">{t('payoutSettingsPage.dac7description')}</span>
                    {!organization?.hasSigned &&
                        <Alert
                            title={t('payoutSettingsPage.dac7NotSignedTitle')}
                            template="warning"
                            description={t('payoutSettingsPage.dac7NotSignedDescription')} />
                    }
                    {organization?.hasSigned &&
                        <Alert
                            title={t('payoutSettingsPage.dac7SignedTitle')}
                            template="success"
                            description={t('payoutSettingsPage.dac7SignedDescription')} />
                    }
                    <div className="flex justify-center">
                        <ButtonText className="mt-6 max-w-xs w-full" onClick={() => { handleSign(); }} isSecondary={true} isLoading={false}>
                            {t('payoutSettingsPage.sign')}
                        </ButtonText>
                    </div>
                </Widget>
            </div>
        );
    }
}

export default SettingsPage;