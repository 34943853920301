import Modal from "../../common/modal";
import InputText from "../../common/inputText";
import InputNumber from 'components/common/inputNumber';

import ButtonText from "../../common/buttonText";
import { useState } from "react";
import RadioButton from "components/common/radioButton/radioButton";
import { ReactComponent as InfoIcon } from "assets/icons/ico_info.svg";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const PermitQueuePositionModal = ({ display, setDisplay, totalItems, queueItem, onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [position, setPosition] = useState(0);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const showDisclaimer = searchParams.get("positionInQueue");
  const queuePositionOptions = [
    {
      key: "top",
      text: t("permitQueuePage.moveToTop"),
    },
    {
      key: "bottom",
      text: t("permitQueuePage.moveToBottom"),
    },
    {
      key: "specificSpot",
      text: t("permitQueuePage.moveToSpecificSpot"),
    },
  ];
  return (
    <Modal
      title={t("permitQueuePage.changeQueuePosition", {
        name: queueItem.firstName + " " + queueItem.lastName,
      })}
      overlayClassName={"items-center justify-center"}
      modalClassName={"max-h-[96vh] overflow-y-auto flex-col z-50"}
      innerClassName={"w-[95vw] md:w-[40vw] overflow-y-auto"}
      display={display}
      setDisplay={setDisplay}
    >
      {queuePositionOptions.map((option, index) => (
        <div key={index} className="flex items-center flex-row mb-2">
          <RadioButton
            isChecked={selectedOption === option.key}
            onClick={(e) => {
              setSelectedOption(option.key);
              setPosition(option.key !== "specificSpot" ? option.key : 1);
            }}
          />
          <span className="ml-2">{option.text}</span>
        </div>
      ))}

      {selectedOption === "specificSpot" && (
        <InputNumber
          title={t("permitQueuePage.enterPosition", { totalItems })}
          value={position}
          min={1}
          max={totalItems}
          onChange={(value) => {
            if (value > totalItems) {
              value = totalItems;
            } else if (value < 1 && value) {
              value = 1;
            }
            setPosition(value);
          }}
        />
      )}
      {showDisclaimer === "true" && (
        <div className="mt-6 text-sm text-gray-600 flex  flex-col gap-2 bg-gray- rounded-md p-2">
          <div className="flex flex-row">
            <InfoIcon className="h-5 w-5 mr-2" />
            <strong>{t("permitQueuePage.disclaimer")}:</strong>
          </div>
          <p>{t("permitQueuePage.disclaimerDescription")}</p>
        </div>
      )}

      <ButtonText
        onClick={() => {
          onSubmit(position);
        }}
        className="flex mt-6 justify-center ml-auto"
      >
        {t("permitQueueItemModal.buttonSave")}
      </ButtonText>
    </Modal>
  );
};
