import React from "react";
import Lottie from 'lottie-react';
import animSpinnerWhite from 'assets/animations/anim_spinner_white_20x20.json';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';

const ButtonText = ({ children, onClick, isSecondary, isDelete, className, isLoading, disabled, isQueue}) => {

    let textColor = isSecondary ? 'text-airpark-green' : 'text-white';
    let shadow = isSecondary ? '' : 'shadow';
    let backgroundColor;

    if (!isSecondary) {
        if (!isLoading) {
            backgroundColor = 'bg-airpark-green hover:bg-airpark-green-400';
        } else {
            backgroundColor = 'bg-airpark-green background-opacity-70'
        }
    } else {
        backgroundColor = 'bg-white border border-airpark-gray-400 hover:bg-[#EEEEEF]';
    }

    if (isDelete) {
        if (!isLoading) {
            backgroundColor = 'bg-airpark-red hover:bg-airpark-strawberry-400';
        } else {
            backgroundColor = 'bg-airpark-red background-opacity-70'
        }
    }

    if (isQueue) {
        if (!isLoading) {
            backgroundColor = 'bg-airpark-green';
        } else {
            backgroundColor = 'bg-airpark-green background-opacity-70'
        }
    }

    let baseClassName = `flex center text-base font-medium items-center justify-center pl-6 pr-6 h-11 rounded-3xl transition-all duration-200 ease-in-out whitespace-nowrap ${textColor} ${backgroundColor} ${shadow} disabled:opacity-50 disabled:cursor-not-allowed`;

    if (className) {
        className = `${baseClassName} ${className}`;
    } else {
        className = baseClassName;
    }

    return (
        <button onClick={(e) => { e.preventDefault(); onClick(e); }} className={className} disabled={disabled}>
            {!isLoading &&
                <>
                    {children}
                </>
            }
            {isLoading &&
                <Lottie className="h-8 w-8" animationData={isSecondary ? animSpinnerGreen : animSpinnerWhite} loop={true} />
            }
        </button>
    )
}

export default ButtonText;