import Widget from "components/dashboard/widget";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as QueueLargeIcon } from 'assets/icons/ico_queue_large.svg';
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import { useParams, useSearchParams } from "react-router-dom";
import { useMessages } from "context/messages";
import { useTranslation } from "react-i18next";
import { getPermitQueue } from "api/permitQueue";
import { getToken } from "context/auth";
import PermitQueueTable from "components/pageSpecific/permitQueue/permitQueueTable";
import ButtonText from "../../components/common/buttonText";
import PermitQueueItemModal from "../../components/pageSpecific/permitQueue/permitQueueItemModal";
import Tabs from "../../components/common/tabs";
import { PermitQueueType } from "../../helpers/consts";
import PermitQueueSettings from "./permitQueueSettings";
const PERMIT_QUEUE_TAB_URL_PARAM_NAME = 'tab';

const PermitQueuePage = () => {

    const { t } = useTranslation();
    const { showToast } = useMessages();
    const [isLoading, setIsLoading] = useState(true);
    const { parkingAreaExtId } = useParams();
    const [queueItems, setQueueItems] = useState([]);
    const [displayManageModal, setDisplayManageModal] = useState(false);
    const [ managedQueueItem, setManagedQueueItem ] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [ permitQueueTab, setPermitQueueTab ] = useState(searchParams.get(PERMIT_QUEUE_TAB_URL_PARAM_NAME) ?? PermitQueueType.AreaParking)
    

    const permitQueueTabs = [
        {
            title: t('permitQueuePage.areaParkingQueue'),
            value: PermitQueueType.AreaParking
        }, {
            title: t('permitQueuePage.specificSpotsQueue'),
            value: PermitQueueType.AnyNumberedSpot
        }
    ]

    const onChangeTab = (tab) => {
        setPermitQueueTab(tab);
        const newParams = new URLSearchParams(searchParams);
        newParams.set(PERMIT_QUEUE_TAB_URL_PARAM_NAME, tab);
        setSearchParams(newParams);
    }

    const onEditClick = (queueItem) => {
        setManagedQueueItem(queueItem);
        setDisplayManageModal(true);
    }

    const onAddQueueItem = (item) => {
        item.queuePosition = queueItems.length + 1;
        setQueueItems([...queueItems, item]);
        if (PermitQueueType[item.permitQueueType]) {
            setPermitQueueTab(item.permitQueueType);
        }
    }

    const onEditQueueItem = () => {
        // refetch queue
        fetchQueue();
    }

    const onRemoveQueueItem = () => {
        // refetch queue
        fetchQueue();
    }

    const onUpdateQueuePosition = () => {
      // refetch queue
      fetchQueue();
    } 

    const fetchQueue = async () => {
        try {
            const token = await getToken();
            const result = await getPermitQueue(token, parkingAreaExtId);

            if (result && !result.error) {
                setQueueItems(result);
            } else {
                console.error(result.error);
                showToast(t('generic.errorTitle'), t('permitQueueTable.queueCantBeFetched'), 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsLoading(false);
    }

    const areaParkingQueueItems = useMemo(() => {
        return queueItems.filter((item) => item.permitQueueType === PermitQueueType.AreaParking).sort((a, b) => a.order - b.order);
    }, [queueItems]);

    const specificSpotQueueItems = useMemo(() => {
        return queueItems.filter((item) => item.permitQueueType === PermitQueueType.AnyNumberedSpot);
    }, [queueItems]);

    useEffect(() => {
        fetchQueue();
    }, [])

    return (
      <>
        <Widget>
          <div className="flex md:flex-row flex-col-reverse gap-12 justify-between items-center">
            <div className="flex flex-col">
              <h2 className="text-xl font-medium mb-3">
                {t("permitQueuePage.title")}
              </h2>
              <span>{t("permitQueuePage.description")}</span>
            </div>
            <span className="flex w-20 h-20">
              <QueueLargeIcon />
            </span>
          </div>
        </Widget>
        <PermitQueueSettings/>
        <Tabs
          tabs={permitQueueTabs}
          activeTab={permitQueueTab}
          setActiveTab={onChangeTab}
          className="mt-6"
        />
        {isLoading && (
          <div className="mt-6">
            <WidgetLoadingSkeleton />
          </div>
        )}
        {!isLoading && (
          <Widget className="mt-6">
            <div className="md:flex md:items-center items-left justify-between mb-6 flex-col md:flex-row">
              <h2 className="text-xl font-medium mb-3">
                {permitQueueTab === PermitQueueType.AreaParking
                  ? t("permitQueuePage.usersInAreaParkingQueue")
                  : t("permitQueuePage.usersInSpecificSpotQueue")}
              </h2>
              <div className="ml-auto mr-4 flex items-center"></div>
              <ButtonText
                onClick={() => {
                  setManagedQueueItem(null);
                  setDisplayManageModal(true);
                }}
              >
                {t("permitQueuePage.addUserToQueue")}
              </ButtonText>
            </div>
            <PermitQueueTable
              queueItems={
                permitQueueTab === PermitQueueType.AreaParking
                  ? areaParkingQueueItems
                  : specificSpotQueueItems
              }
              setQueueItems={setQueueItems}
              onEditClick={onEditClick}
              onRemoveQueueItem={onRemoveQueueItem}
              onUpdateQueuePosition={onUpdateQueuePosition}
            />
          </Widget>
        )}
        {displayManageModal && (
          <PermitQueueItemModal
            display={displayManageModal}
            setDisplay={setDisplayManageModal}
            queueItem={managedQueueItem}
            queueType={permitQueueTab}
            onAddQueueItem={onAddQueueItem}
            onEditQueueItem={onEditQueueItem}
          />
        )}
      </>
    );
}

export default PermitQueuePage;
