import objectToFormData from "helpers/objectToFormData";

const BASE_URL_PERMITS = `${process.env.REACT_APP_API_PERMITS_BASE_URL}/Permits`;
const BASE_URL_MARKETPLACE = `${process.env.REACT_APP_API_PERMITS_BASE_URL}/Marketplace`;
const BASE_URL_CUSTOM_AGREEMENT = `${process.env.REACT_APP_API_PERMITS_BASE_URL}/CustomAgreement`;
const BASE_URL_PARKING_AREAS_QUEUE_SETTINGS = `${process.env.REACT_APP_API_PERMITS_BASE_URL}/ParkingAreaQueueSettings`;

export const getPermitsForParkingArea = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL_PERMITS + `/get-permits-for-parking-area/${parkingAreaExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getPermitsForParkingSpot = async (token, parkingSpotExtId) => {
  const response = await fetch(BASE_URL_PERMITS + `/get-permits-for-parking-spot/${parkingSpotExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getParkingSpotsWithNumberOfPermitsForParkingArea = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL_PERMITS + `/get-parking-spots-with-permits-for-parking-area/${parkingAreaExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getMarketplacePermitsForParkingArea = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL_MARKETPLACE + `/get-marketplace-permits-for-parking-area/${parkingAreaExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getPermit = async (token, permitExtId) => {
  const response = await fetch(BASE_URL_PERMITS + `/get-permit/${permitExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const createPermit = async (token, permit) => {
  const response = await fetch(BASE_URL_PERMITS + '/create-permit', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(permit)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const createMarketplacePermit = async (token, permit) => {
  const response = await fetch(BASE_URL_MARKETPLACE + '/create-marketplace-permit', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(permit)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const updatePermit = async (token, permit) => {
  const response = await fetch(BASE_URL_PERMITS + '/update-permit', {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(permit)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const revokePermit = async (token, permitExtId, reAddToMarketplace) => {
  const response = await fetch(BASE_URL_PERMITS + `/revoke-permit/${permitExtId}`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      reAddToMarketplace
    })
  });

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}

export const deletePermit = async (token, permitExtId) => {
  const response = await fetch(BASE_URL_PERMITS + `/delete-permit/${permitExtId}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}

export const getPermitStatisticsForParkingArea = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL_PERMITS + `/get-permit-statistics-for-parking-area/${parkingAreaExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getCustomAgreement = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL_CUSTOM_AGREEMENT + `/get-custom-agreement-for-parking-area/${parkingAreaExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const addCustomAgreement = async (token, customAgreement, parkingAreaExtId) => {

  const formData = objectToFormData(customAgreement);

  const response = await fetch(BASE_URL_CUSTOM_AGREEMENT + `/add-custom-agreement-to-parking-area/${parkingAreaExtId}`, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${token}`
    },
    body: formData
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const deleteCustomAgreement = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL_CUSTOM_AGREEMENT + `/remove-custom-agreement-from-parking-area/${parkingAreaExtId}`, {
    method: 'DELETE',
    headers: {
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}

export const getParkingAreaQueueSettings = async (token, parkingAreaExtId) => {  
  const response = await fetch(BASE_URL_PARKING_AREAS_QUEUE_SETTINGS + `/${parkingAreaExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    return response.json();
  }

  throw response;
};

export const addParkingAreaQueueSettings = async (token, parkingAreaExtId, updatedValues) => {
  const response = await fetch(BASE_URL_PARKING_AREAS_QUEUE_SETTINGS + `/${parkingAreaExtId}`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(updatedValues)
  });

  if (response.status === 200) {
    return response.json();
  }

  throw response;
};